/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type avatar_user$ref: FragmentReference;
declare export opaque type avatar_user$fragmentType: avatar_user$ref;
export type avatar_user = {|
  +firstname: ?string,
  +lastname: ?string,
  +logo: ?string,
  +avatar: ?{|
    +nameId: ?string,
    +extension: ?string,
  |},
  +$refType: avatar_user$ref,
|};
export type avatar_user$data = avatar_user;
export type avatar_user$key = {
  +$data?: avatar_user$data,
  +$fragmentRefs: avatar_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "avatar_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Picture",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extension",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9e98f99969aec8efd420339739f2aaab';

module.exports = node;
