import { useRef, useEffect, useState } from 'react';
import Icon from '@/components/core/icon.js';

function ModalWrapper({
  // eslint-disable-next-line react/prop-types
  children, onClose, afterRender, delay, close = true,
}) {
  const contentRef = useRef();
  const [show, setShow] = useState(!delay);

  useEffect(() => {
    function handleClickOutside(event) {
      if (contentRef && !contentRef.current.contains(event.target)) {
        if (onClose) onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  useEffect(() => {
    if (afterRender) {
      // console.log('modal afterRender');
      afterRender();
    }
  }, [afterRender]);

  useEffect(() => {
    let timer;
    if (delay) {
      if (!show) {
        timer = setTimeout(() => setShow(true), delay);
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show]);

  return (
    <div className="fixed z-60 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center h-screen-mobile pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black-light bg-opacity-20" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="md:absolute md:top-[70px] md:right-20 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
          <div className="relative">
            {close && (
            <div className="z-10 transition duration-300 cursor-pointer group rounded-full flex items-center justify-center absolute p-2 h-10 w-10 top-4 right-4 hover:bg-white-dark" onClick={() => onClose()}>
              <Icon name="close" className="transition duration-300 w-4 fill-current md:text-white group-hover:text-black" />
            </div>
            )}
            <div className="sm:flex sm:items-start">
              <div className="w-full text-center sm:mt-0 sm:text-left" ref={contentRef}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalWrapper;
