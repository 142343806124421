import {
  useImperativeHandle, forwardRef, useState, useEffect,
} from 'react';
import { useRouter } from 'next/navigation';
import { nanoid } from 'nanoid';
import { lang } from '@/helpers/translate';
import { getPathByLang } from '@/helpers/router.js';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';

const ComponentGotoAddSpaceV3 = forwardRef((props, ref) => {
  const what = useZusUserConnectedStore((state) => state.what);
  const isAuthenticated = useZusUserConnectedStore((state) => state.isAuthenticated);
  const userData = useZusUserConnectedStore((state) => state.userData);
  const userAuthOpenModal = useZusUserConnectedStore((state) => state.userAuthOpenModal);

  const [step2Done] = useState(userData?.signup?.step2);
  const [askAddSpace, setAskAddSpace] = useState(null);

  const router = useRouter(); const
    langCurrent = lang();

  useImperativeHandle(ref, () => ({
    setAskAddSpace,
  }));

  function pushRouter() {
    // console.log('goto add space V3');
    router.push(getPathByLang('add_space_v3', langCurrent));
  }

  function gotoAddSpace() {
    if (isAuthenticated && userData?.signup?.step2 === true && askAddSpace) {
      pushRouter();
    }
  }

  useEffect(() => {
    if ((/^clickonaddspacefromheaderaddspacefrombecomehost/).test(what)) {
      // console.log('clickonaddspacefromheaderaddspacefrombecomehost');
      setAskAddSpace(nanoid());
    } else if ((/^cleanleavefrombecomehost/).test(what)) {
      // console.log('cleanleavefrombecomehost');
      setAskAddSpace(null);
    } else if ((/^clickonaddspacefromheaderuserconnected/).test(what)) {
      pushRouter();
    }
  }, [what]);

  useEffect(() => {
    // console.log('askAddSpace', askAddSpace);
    if (askAddSpace) {
      if (!isAuthenticated) userAuthOpenModal('signup');
      else gotoAddSpace();
    }
  }, [askAddSpace]);

  useEffect(() => {
    // console.log('isAuthenticated, userData?.signup?.step2', isAuthenticated, userData?.signup?.step2, step2Done, askAddSpace);
    if (isAuthenticated && userData?.signup?.step2 === true && step2Done !== true && askAddSpace) {
      gotoAddSpace();
    }
  }, [isAuthenticated, userData]);

  return null;
});

export default ComponentGotoAddSpaceV3;
