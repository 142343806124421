import { useRouter } from 'next/navigation';
import {
  Search,
} from '@/core/dynamic';
import { UseZusModalStore, ModalWrapper } from '@/helpers/modal.js';
import { useTranslation } from '@/helpers/translate';
import Icon from '@/core/icon';

function SearchCommon({ desktop, notdesktop }) {
  const { t } = useTranslation();
  const router = useRouter();

  function openSearch() {
    const { setModal1 } = UseZusModalStore.getState();
    setModal1(
      <ModalWrapper onClose={() => setModal1(null)}>
        <Search
          isPopin
          onClose={(routerData) => {
          setTimeout(() => {
            router.push(routerData);
            setModal1(null);
          }, 0);
        }}
        />
      </ModalWrapper>,
    );
  }

  if (desktop) {
    return (
      <div className="hidden ml-6 search">
        <button className="relative flex items-center pl-4 pr-12 leading-none bg-white border rounded-full min-w-300 h-11 border-white-dark4 text-dark hover:text-black hover:bg-white-dark shadow-light" onClick={openSearch}>
          {t('header:begin_search')}
          <div className="absolute flex items-center justify-center w-8 h-8 ml-3 rounded-full right-1 blue-gradient">
            <Icon name="search" className="w-4 text-white" />
          </div>
        </button>
      </div>
    );
  }
  if (notdesktop) {
    return (
      <button className="flex flex-col items-center px-1 py-2 min-w-16 text-dark" onClick={openSearch}>
        <div className="flex items-center h-8"><Icon name="search" className="block h-7 w-7 text-blue" /></div>
        <span className="pt-1 text-xs text-">{t('header:search')}</span>
      </button>
    );
  }
}

export default SearchCommon;
