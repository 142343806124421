import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { Markdown, useTranslation, lang } from '@/helpers/translate';
import ModalWrapper from '@/helpers/modals/skin1.js';
import { useZusModalStore } from '@/helpers/modal.js';

function MyLink({ children, ...props }) {
  const langCurrent = lang();
  return (
    <Link {...props} locale={langCurrent}>{children[0]}</Link>
  );
}

function Cities(props) {
  const { t } = useTranslation();
  const { setModal1 } = useZusModalStore.getState();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const langCurrent = lang();

  const trad = t('guides:cities', {}, { returnObjects: true });

  useEffect(() => {
    setModal1(null);
  }, [searchParams, pathname]);

  function renderItemList(obj) {
    if (!obj || !obj.children) {
      return null;
    }
    const children = Object.values(obj.children).map((item) => (
      <li className="text-dark hover:text-black" key={item}>
        <Markdown options={{ overrides: { a: { component: MyLink } } }}>{item}</Markdown>
      </li>
    ));
    return (
      <ul className="text-dark leading-7 grid grid-cols-2 gap-x-4 md:gap-x-10 md:grid-cols-2">
        {children}
      </ul>
    );
  }

  function renderLinks() {
    return (
      <nav className="w-80v md:w-[350px] p-6 md:px-7 md:py-5 text-left text-[15px]">
        {renderItemList(trad?.menu1)}
      </nav>
    );
  }
  function renderCities() {
    return (
      <div className="flex">
        {renderLinks()}
      </div>
    );
  }

  function openCities() {
    setModal1(
      <div>
        <ModalWrapper onClose={() => { setModal1(null); }} delay={100}>
          {renderCities()}
        </ModalWrapper>
      </div>,
    );
  }

  return (
    <>
      <a className="relative" href="#" key={nanoid()} {...props} onClick={() => { openCities(); }}>{t('guides:cities.menu1.title')}</a>
      <div className="hidden">{renderLinks()}</div>
    </>
  );
}

export default Cities;
