import { useState } from 'react';
import { useFragment } from 'react-relay';
import { nanoid } from 'nanoid';
import { Menu } from '@headlessui/react';
import { fragmentCate, query } from '@/components/fields/categories.js';
import { useTranslation } from '@/helpers/translate';
import Icon from '@/core/icon';
import Emitter from '@/helpers/emitter.js';
import { useSearchZustandStore } from '@/helpers/searchctx.js';
import miniQueryLoader from '@/helpers/miniQueryLoader';

function Categories({ data }) {
  const categories = data?.categories || [];

  const { t } = useTranslation();
  const { category } = useSearchZustandStore.getState();

  const slugs = t('category:slug', {}, { returnObjects: true });
  let categoryslug = category || t('category:slug.meeting');

  categoryslug = Object.entries(slugs).find(([catetrad, cate]) => catetrad === categoryslug); // eslint-disable-line no-unused-vars, max-len
  categoryslug = categoryslug ? categoryslug[0] : 'meeting';

  const cateDefault = categories.find((cate) => cate.slug === categoryslug);

  const [cateSelected, setCateSelected] = useState(cateDefault);

  function onChange(categoryParams) {
    Emitter.emit('headersearchpagechangecategory', categoryParams);
    setCateSelected(categories.find((cate) => cate.slug === categoryParams));
  }

  return (
    <Menu>
      <div className="relative">
        <Menu.Button className="flex items-center font-medium text-black-light rounded-full rounded-tr-none rounded-br-none border-white-dark4 py-2 pl-4 pr-2 border-r hover:bg-white-dark">
          {t(`category:${cateSelected.slug}`)}
          <Icon name="next" className="transform rotate-90 ml-2 w-5 h-5 text-dark" />
        </Menu.Button>
        <Menu.Items className="z-40 absolute rounded-lg mt-2 left-0 bg-white text-dark shadow-button overflow-hidden">
          {categories.map((cate) => (
            <Menu.Item className="cursor-pointer transition flex flex-col text-dark hover:bg-blue-light hover:text-blue px-5 py-2" key={nanoid()}>
              {() => (
                <span onClick={() => onChange(cate.slug)} className="cursor-pointer text-blue pt-2 pb-2 transition duration-300 group-hover:text-blue">{t(`category:${cate.slug}`)}</span>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default miniQueryLoader({
  Component: ({ data }) => {
    const dataFragment = useFragment(fragmentCate, data);
    return <Categories data={dataFragment} />;
  },
  query,
});
