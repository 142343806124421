import { useEffect, useState, Fragment } from 'react';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import { useZusModalStore, ModalWrapper as ModalWrapperComponent } from '@/helpers/modal.js';

import {
  HeaderLogin,
  HeaderSignup,
  HeaderSignupStep2,
  HeaderConfirm,
  HeaderRegisterCompany,
  HeaderLoginCorpo,
} from '@/core/dynamic';

const setNoModal = (component) => component;
const CustomFragment = ({ children }) => children;

function LoginSignupContainer({ fromCorpo, noModal }) {
  const what = useZusUserConnectedStore((state) => state.what);
  const [currentWhat, setCurrentWhat] = useState([what]);
  const userAuthOpenModal = useZusUserConnectedStore((state) => state.userAuthOpenModal);
  const { setModal1: setModalMode } = useZusModalStore.getState();
  const [currentLastOne, setCurrentLastOne] = useState(null);

  function onClose() {
    if (fromCorpo) return true;
    return userAuthOpenModal();
  }

  const renderContent = ({ lastOne }) => {
    let ModalWrapper;
    let setModal1;
    if (noModal) {
      ModalWrapper = CustomFragment;
      setModal1 = setNoModal;
    } else {
      ModalWrapper = ModalWrapperComponent;
      setModal1 = setModalMode;
    }

    if (what === 'login') {
      return setModal1(
        <ModalWrapper onClose={() => onClose()} delay={1}>
          <HeaderLogin callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'signup') {
      return setModal1(
        <ModalWrapper onClose={() => onClose()} delay={1}>
          <HeaderSignup callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'signupstep2') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderSignupStep2 callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'corpostep') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderLoginCorpo callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'companyconfirm') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderConfirm callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'registercompany') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderRegisterCompany callback={() => onClose()} lastStep={currentLastOne || lastOne} />
        </ModalWrapper>,
      );
    }
    setModal1(null);
    return null;
  };

  useEffect(() => {
    const lastOne = currentWhat.pop();
    currentWhat.push(what);

    if (!noModal) {
      renderContent({ lastOne });
    } else {
      /*
        MI-1164, save it cause by signe-blank, using one page, no modal
      */
      setCurrentLastOne(lastOne);
    }
    setCurrentWhat([...currentWhat]);

    return () => {};
  }, [what]);

  return !noModal ? null : renderContent({});
}

export default LoginSignupContainer;
