import {
  useRef, useEffect, useState,
} from 'react';
import { nanoid } from 'nanoid';
import Categories from '@/components/search/filters/categories.js';
import Hubs from '@/components/fields/hubs.js';
import { useSearchZustandStore } from '@/helpers/searchctx.js';
import Emitter from '@/helpers/emitter.js';
import { getPlaceData } from '@/helpers/position.js';
import { useTranslation } from '@/helpers/translate';

function EventListener() {
  function changeCategory(category) {
    // console.log('fnSearchFromSearchPage category', category);
    const { initParams } = useSearchZustandStore.getState();
    useSearchZustandStore.setState({
      category,
      initParams: { ...initParams, category },
      searchEvent: { action: 'searchPageSearchcallsearch' },
      clearAllMarkers: nanoid(),
      userUsingPlateform: nanoid(),
    });
    return true;
  }

  async function changeHub(hub) {
    if (typeof google !== 'undefined' && hub) {
      // console.log('fnSearchFromSearchPage hub', hub);
      let location = { ...hub?.location };
      if (hub.gg_place_id) {
        const resPlace = await getPlaceData({ place_id: hub.gg_place_id });
        if (!resPlace) return alert('Please contact OfficeRider client support');
        // console.log('resPlaceresPlace', resPlace);
        location = {
          ...resPlace.location,
        };
      }

      Emitter.emit('search_map_build_clearAllMarkers', true);
      Emitter.emit('search_map_build_moveMap', {
        ...location, zoom: 13, hub: hub.slug, title: hub.title,
      });
    }
    return true;
  }

  useEffect(() => {
    Emitter.on('headersearchpagechangecategory', changeCategory);
    Emitter.on('headersearchpagechangehub', changeHub);
    return () => {
      Emitter.off('headersearchpagechangecategory', changeCategory);
      Emitter.off('headersearchpagechangehub', changeHub);
    };
  }, []);

  return null;
}

function SearchPageComponent() {
  const { t } = useTranslation();
  const [hub] = useState({
    title: useSearchZustandStore?.getState().initParams?.title || '',
    location: {
      lat: useSearchZustandStore?.getState().initParams?.lat,
      lng: useSearchZustandStore?.getState().initParams?.lng,
    },
  });
  const hubsRef = useRef();

  return (
    <div className="fixed top-0 z-40 flex items-center my-3 text-lg right-16 left-4 max-w-5/6 lg:static lg:ml-6">
      <span className="hidden mr-3 text-xl lg:flex text-dark">{t('search:title')}</span>
      <div className="flex w-full text-base border rounded-full lg:text-lg border-white-dark3 lg:shadow-light">
        <Categories />
        <Hubs ref={hubsRef} fromSearchPage defaultTitle={hub.title} classesDefault={{ container: 'relative text-base lg:text-lg w-full lg:w-72 no-margin', input: '  text-base lg:text-lg w-full lg:w-72 font-medium lg:  rounded-r-full truncate px-3 border-none py-2 text-black-light focus:border-0 hover:bg-white-dark focus:bg-white-dark focus:ring-0' }} />
      </div>
      <EventListener />
    </div>
  );
}

export default SearchPageComponent;
