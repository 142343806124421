import { useEffect, useState } from 'react';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';

function LoginIconClient() {
  const { comefrom } = useZusUserConnectedStore.getState();
  const [css, setCss] = useState('relative flex items-center justify-between');

  useEffect(() => {
    if (comefrom === 'space-public') {
      setCss('relative flex items-center justify-between hidden');
    }
  }, [comefrom]);

  return css;
}

export default LoginIconClient;
