/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { avatar_user$ref } from "./avatar_user.graphql";
import type { bookmark_book$ref } from "./bookmark_book.graphql";
import type { layoutCorpoData$ref } from "./layoutCorpoData.graphql";
import type { FragmentReference } from "relay-runtime";
declare export opaque type header_userConnected$ref: FragmentReference;
declare export opaque type header_userConnected$fragmentType: header_userConnected$ref;
export type header_userConnected = {|
  +userConnected: ?{|
    +ref: string,
    +fullUserName: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +email: any,
    +logo: ?string,
    +bookmark: ?{|
      +$fragmentRefs: bookmark_book$ref
    |},
    +host: ?boolean,
    +signup: ?{|
      +checkData: ?boolean,
      +step1: ?boolean,
      +step2: ?boolean,
      +data: ?any,
    |},
    +company: ?{|
      +name: ?string
    |},
    +hasBookings: ?boolean,
    +hasSpaces: ?boolean,
    +hasGuestBookings: ?boolean,
    +corpo: ?{|
      +ref: string,
      +name: ?string,
      +$fragmentRefs: layoutCorpoData$ref,
    |},
    +corpoUserConf: ?{|
      +enable: ?boolean,
      +status: ?string,
      +isAdmin: ?boolean,
      +requireValidEmail: ?boolean,
    |},
    +$fragmentRefs: avatar_user$ref,
  |},
  +$refType: header_userConnected$ref,
|};
export type header_userConnected$data = header_userConnected;
export type header_userConnected$key = {
  +$data?: header_userConnected$data,
  +$fragmentRefs: header_userConnected$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ref",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "header_userConnected",
  "selections": [
    {
      "alias": "userConnected",
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullUserName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Bookmark",
          "kind": "LinkedField",
          "name": "bookmark",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "bookmark_book"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "host",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSignup",
          "kind": "LinkedField",
          "name": "signup",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkData",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "step1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "step2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "data",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasBookings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasSpaces",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasGuestBookings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Corpo",
          "kind": "LinkedField",
          "name": "corpo",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "layoutCorpoData"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CorpoUser",
          "kind": "LinkedField",
          "name": "corpoUserConf",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isAdmin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requireValidEmail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "avatar_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a693902a4900cee0d34c9c6dbdee919e';

module.exports = node;
